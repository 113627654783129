import React from "react";
import { MediumComponentContainer, PageContainer } from "styles/global.style";
import {
  ButtonContainer,
  CenteredCol,
  LogoContainer,
  OuterContainer,
  StyledProductHunt,
  TitleCol,
  WrapperContainer,
} from "components/ui/base/ProductHunt/index.style";
import { Container, Row } from "styled-bootstrap-grid";
import { useTranslation } from "react-i18next";
import { Title } from "components/core/Typography/Title";
import Fade from "components/core/Animation/extended/Fade";
import StaticImg from "components/core/StaticImg";

export interface ProductHuntProps {
  url: string;
}

const ProductHunt = (props: ProductHuntProps) => {
  const { t } = useTranslation();

  return (
    <StyledProductHunt>
      <MediumComponentContainer>
        <OuterContainer>
          <WrapperContainer>
            <PageContainer>
              <Container>
                <Fade direction={"down"}>
                  <Row>
                    <TitleCol sm={12} md={9}>
                      <LogoContainer>
                        <StaticImg
                          maxWidth={100}
                          alt={"Product Hunt"}
                          src={"components/product-hunt/logo.png"}
                        />
                      </LogoContainer>
                      <Title>{t("components.ui.base.ProductHunt.title")}</Title>
                    </TitleCol>
                    <CenteredCol sm={12} md={3}>
                      <ButtonContainer>
                        <a
                          href="https://www.producthunt.com/posts/customerly-aura?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-customerly&#0045;aura"
                          target="_blank"
                        >
                          <img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=441763&theme=light"
                            alt="Customerly&#0032;Aura - AI&#0032;Assistant&#0032;for&#0032;Support&#0032;Teams | Product Hunt"
                            width="250"
                            height="54"
                          />
                        </a>
                      </ButtonContainer>
                    </CenteredCol>
                  </Row>
                </Fade>
              </Container>
            </PageContainer>
          </WrapperContainer>
        </OuterContainer>
      </MediumComponentContainer>
    </StyledProductHunt>
  );
};

export default ProductHunt;
